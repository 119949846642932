<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	Vue.component("HomeSlider", () => import("@frontend/part/HomeSlider.vue"))

	export default {
		extends: BaseVue,
		data() {
			return {
				category: {},
				staticHero:{}
			}
		},
		computed: {
		},
		async mounted() {
			this.$set(this.$root, 'page', this)
			this.getCategory()
			this.apiGetStatic()
			setTimeout(()=>{
				global.SEMICOLON.initialize.fullScreen()
				Gen.loadScript("/frontend/js/custom.js", false)
				$("#FoMain #wrapper > *:not(#header)").css("opacity", 1)
			}, 100)
		},
		methods: {
			getCategory() {
				Gen.apirest("/health-list-category", {}, (err, resp) => {
					if (err) console.log(err)
					this.category = resp.data
				})
			},
			async getStatic(id){
	            return (await Gen.apirest('/static-hero', {id:id}, ()=>{})).data
	        },
	        async apiGetStatic(){
	            this.staticHero = await this.getStatic(8)
	        },
		},
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element slider-parallax half-slider force-full-screen full-screen clearfix"
			data-loop="true" data-autoplay="5000">
			<div class="force-full-screen full-screen" :style="'background-image: url('+uploader(staticHero.ash_img)+');'" :data-hero-mobile="uploader(staticHero.ash_img)">
				<div class="container clearfix">
					<div class="slider-caption slider-caption-center">
						<h2 data-animate="fadeInDown" v-html="staticHero.ash_title"></h2>
						<p data-animate="fadeInUp" data-delay="400" v-html="staticHero.ash_sub_title"></p>
					</div>
				</div>
			</div>
		</section>

		<section id="content">
			<div class="content-wrap">
				<section class="section nobg nomargin banner-listing">
					<div class="container">
						<div v-for="(v,k) in category" :class="'banner no-bottom health '+v.rev_img">
							<div class="ban-bg" data-aos="zoom-out" :style="'background-image: url('+uploader(v.mht_back_image)+')'"></div>
							<div class="row">
								<div class="col-lg-5">
									<div class="banner-text">
										<h2>{{v.mht_title}}</h2>
										<p v-html="v.mht_detail"></p>
										<router-link :to="{name:'HealthInfoDetail', params:{slug:v.mht_slug}}" class="primary-btn" v-if="v.mht_have_subcategory=='Y'">
											{{web.btn_pelajari_selanjutnya}}
										</router-link>
										
										<router-link :to="{name:'HealthInfoDetailArticle', params:{slug:v.mht_slug,sub:'article'}}" class="primary-btn" v-else>
											{{web.btn_pelajari_selanjutnya}}
										</router-link>
									</div>
								</div>
								<div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
									<img :src="uploader(v.mht_image)" :alt="v.mht_title" :title="v.mht_title">
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section>
	</div>
</template>